/** * 案例  */
<template>
  <v-app class="section-box">
    <v-banner
      single-line
      height="300"
      class="banner"
    >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/vrPanorams-case-banner.png"
        height="100%"
        class="d-flex align-center"
        >
          <div class="banner-box">
            <p class="banner-title">合作案例</p>
            <p class="banner-text">点燃你的创意火花</p>
          </div>
      </v-img>
    </v-banner>
    
    <v-tabs
      centered
      fixed-tabs
      hide-slider
      class="new-tabs-two"
      v-model="tab"
    >
      <v-tab 
        :value="levelTwoIndex"
        v-for="(v,k) in data[levelOneIndex].tab"
        :key="k"
        @click="clickLevelTwo(k)"
      ><span class="font-size-18">{{ v.tabTilte }}</span></v-tab>
    </v-tabs>
    <div class="div-tabs">

    <v-tabs-items v-model="tab" class="new-tabs-items-two">
      <v-tab-item
        v-model="tab"
        v-for="(v, k) in data[levelOneIndex].tab[levelTwoIndex].tabItem"
        :key="k"
      >
        <v-container fluid>
            <v-card
              class="mx-auto item-card"
              max-width="240"
              max-height="410"
              @click="goCaseDetail(v)"
            >
              <v-img
                max-height="340"
                class="tran-sec"
                :src="v.coverImg"
                :aspect-ratio="9/16"
              >
              </v-img>
              <div class="text-box">
                <v-card-text class="title" v-text="v.itemTitle"></v-card-text>
              </div>
            </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>

    
  </v-app>
</template>

<script>
import textData from '@/static/textData.js'
import qs from 'qs'

export default {
  name: 'Case',
  components: {
  },
  data() {
    return {
      data: [textData.CooperationCase[4]],
      levelOneIndex: 0,
      levelTwoIndex: 0,
      tab: null,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    clickLevelTwo(k) {
      this.levelTwoIndex = k
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.section-box {
  background: #f5f8fd;
  position: relative;
  z-index: 6;
}

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    color: #fff;
    text-align: center;
    .banner-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 53px;
    }
    .banner-text {
      margin-top: 15px;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.new-tabs-two {
  ::v-deep .v-tabs-bar {
    height: 100px !important;
  }
  ::v-deep .v-tabs-bar__content {
    align-items: center;
  }
}


</style>
